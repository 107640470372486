import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'

import photoProfil from '../img/mdrenov/photo-profil.jpg'

import Layout from '../components/Layout'
import PreviewCompatibleImageBandeau from "../components/PreviewCompatibleImageBandeau";
import paragraphs from "lines-to-paragraphs";

const firstParagraph = "<strong>MD Rénov</strong> est une entreprise de rénovation <strong>tout corps d’état</strong> intervenant sur la région Grenobloise et alentours (Isère – 38), qui vous offre la possibilité de réaliser votre projet dans les meilleures conditions.\n" +
    "                                    Notre atout : <strong>un interlocuteur unique</strong> qui vous assure la garantie d’une étude et d’un suivi personnalisé, d’une disponibilité, d’une maîtrise des coûts, des délais et vous libère des contraintes d’organisation et de suivi."

export const IndexPageTemplate = ({
                                      image0,
                                  }) => (
    <div>

        <div>
            <PreviewCompatibleImageBandeau imageInfo={image0}/>
        </div>
        <section className="section section--gradient" style={{marginTop: "0rem"}}>
            <div className="container">
                <div className="section p-0" style={{ padding: '0.5rem' }}>
                    <div className="columns">
                        <div className="column">
                            <div className="content">

                                <header className="has-text-centered has-text-orange">
                                    <h3 style={{ color : "#b3722d" }}>A propos </h3>
                                </header>

                                <div dangerouslySetInnerHTML={{ __html: paragraphs(firstParagraph) }} />

                                <p></p>

                                <p><strong>MD Rénov</strong> vous conseille dans l’élaboration et la conception de votre projet : </p>
                                <ul className="check-list">
                                    <li>Définition des besoins</li>
                                    <li>Prise en compte de vos exigences</li>
                                    <li>Choix des solutions techniques et des matériaux adaptés</li>
                                </ul>

                                <p>Que vous envisagiez une <strong>rénovation complète</strong> de votre logement ou un <strong>simple rafraîchissement</strong> de certaines
                                    pièces de vie, notre accompagnement permet de vous assurer le <strong>bon déroulement des travaux</strong>.</p>

                                <p>Nous intervenons sur <strong>tout le département de l’Isère</strong> (Tullins, Saint-Marcellin, Moirans, Saint-Egrève, Voiron, Voreppe,
                                    Grenoble, Saint-Martin d’Hères, Échirolles, Seyssins, Sassenage, Noyarey, Veurey-Voroize, etc.), que vous soyez <strong>un particulier ou un
                                        professionnel</strong>.
                                </p>

                                <div className="is-underlined">
                                    <p> MD Rénov intervient également dans l’aménagement extérieur.</p>
                                </div>

                                <div className="has-text-centered">
                                    <h3 style={{ color : "#b3722d" }}>Qui suis-je ? </h3>
                                    <img src={photoProfil} alt="Gérant de MDRenov" style={{ width: '300px' }} />
                                </div>
                                <p></p>

                                <p>Fort d’une expérience de 15 ans dans la gestion de chantiers tous corps d’état, j’ai pu acquérir une expertise dans la maîtrise et le suivi des différents intervenants.</p>
                                <p>Je mets aujourd’hui mon savoir-faire à votre disposition et vous conseille dans l’élaboration et la réalisation de vos projets de rénovation.</p>
                                <p>La satisfaction de mon client a toujours été une priorité pour moi. La communication, le respect de ses attentes ainsi que des délais sont les clefs de la bonne réussite d’un projet.</p>

                                <p></p>

                                <p>
                                    N’hésitez pas à faire votre demande de devis gratuit en <strong>      <Link to="/contact" style={{ color : "#b3722d" }}>
                                    cliquant ici
                                </Link></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div >
)

IndexPageTemplate.propTypes = {
    image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                image0={frontmatter.image0}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image0 {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          maxWidth
        }
      }
    }
  }
`


